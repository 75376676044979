import { hex2rgba } from '@utils';

const ACCENT = '#64ffda';
const DARK_BG = '#020c1b';
const BG = '#0a192f';

const theme = {
  colors: {
    darkNavy: DARK_BG,
    navy: BG,
    linen: '#FFF1E6',
    lightlinen: '#fff8f2',
    champagne: '#f7ecd7',
    salmonPink: '#FFA69E',
    coralSea: '#B8F2E6',
    coolGrey: '#AED9E0',
    frenchGrey: '#5E6472',
    lightNavy: '#172a45',
    lightestNavy: '#303C55',
    slate: '#7a7a7a',
    darkSlate: '#696969',
    darkestSlate: '#444444',
    white: '#e6f1ff',
    green: ACCENT,
    transGreen: hex2rgba(ACCENT, 0.07),
    shadowNavy: hex2rgba(DARK_BG, 0.7),
  },

  fonts: {
    Calibre:
      'Calibre, San Francisco, SF Pro Text, -apple-system, system-ui, BlinkMacSystemFont, Roboto, Helvetica Neue, Segoe UI, Arial, sans-serif',
    // SFMono: 'SF Mono, Fira Code, Fira Mono, Roboto Mono, Lucida Console, Monaco, monospace',
    SFMono: 'Source Sans Pro, Helvetica, Arial, sans-serifs',
  },

  fontSizes: {
    xs: '14px',
    smish: '16px',
    sm: '17px',
    md: '18px',
    lg: '19px',
    xl: '22px',
    xxl: '25px',
    h3: '35px',
  },

  easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)',

  borderRadius: '3px',
  navHeight: '100px',
  navScrollHeight: '70px',
  margin: '20px',

  tabHeight: 42,
  tabWidth: 120,
  radius: 3,

  hamburgerWidth: 30,
  hamBefore: `top 0.1s ease-in 0.25s, opacity 0.1s ease-in`,
  hamBeforeActive: `top 0.1s ease-out, opacity 0.1s ease-out 0.12s`,
  hamAfter: `bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)`,
  hamAfterActive: `bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s`,

  navDelay: 1000,
  loaderDelay: 2000,
};

export default theme;
